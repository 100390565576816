import React, { useEffect, useState } from 'react';
import { cryptoListPairSymbols } from '../services/CryptoPairSymbols';

const TransactionTableKucoin = () => {
  const [transactions, setTransactions] = useState([
    {
      time: '10/22/2024 00:28:09',
      pair: 'SOL/USDT',
      side: 'Sell',
      type: 'Limit Order',
      filled: "0.27842 USDT",
      amount: "2,916.6007 SOL",
      volume: "812.03996 USDT",
      fee: '0.81203 USDT',  
    },
    
  ]);

  
  const generateRandomTransaction = () => {
    // const pairs = ['BTC/USDT','ETH/USDT','XRP/USDT','BNB/USDT','DOGE/USDT','TRX/USDT','SOL/USDT'];
    const pairs = cryptoListPairSymbols;
    const sides = ['Buy', 'Sell'];
    const types = ['Limit Order', 'Market Order'];
    const getRandom = (arr) => arr[Math.floor(Math.random() * arr.length)];
    
    return {
      time: new Date().toLocaleString(),
      pair: getRandom(pairs),
      side: getRandom(sides),
      type: getRandom(types),
      filled: (Math.random() * 200).toFixed(5) + ' USDT',
      amount: (Math.random() * 10000).toFixed(2) + ' ' + getRandom(pairs).split('/')[0],
      volume: (Math.random() * 1000).toFixed(2) + ' USDT',
      fee: (Math.random() * 10).toFixed(2) + ' USDT',
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTransactions((prevTransactions) => [
        generateRandomTransaction(),
        ...prevTransactions,
      ]);
    }, 3000); // 3 seconds 60000(1min)

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto bg-gray-800 text-white transac-table">
        <thead className="bg-gray-900">
          <tr>
            <th className="px-4 py-2 text-left">Time</th>
            <th className="px-4 py-2 text-left">Pair</th>
            <th className="px-4 py-2 text-left">Side</th>
            <th className="px-4 py-2 text-left">Type</th>
            <th className="px-4 py-2 text-left">Filled Price</th>
            <th className="px-4 py-2 text-left">Amount</th>
            <th className="px-4 py-2 text-left">Volume</th>
            <th className="px-4 py-2 text-left">Fee</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((tx, index) => (
            <tr key={index} className="border-b border-gray-700">
              <td className="px-4 py-2">{tx.time}</td>
              <td className="px-4 py-2">{tx.pair}</td>
              <td className="px-4 py-2">{tx.side}</td>
              <td className="px-4 py-2">{tx.type}</td>
              <td className="px-4 py-2 shaded-cell">{tx.filled}</td>  
              <td className="px-4 py-2">{tx.amount}</td>
              <td className="px-4 py-2">${Number(tx.volume.replace(' USDT', '')).toLocaleString()}</td>
              <td className="px-4 py-2 shaded-cell">{tx.fee}</td>    
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTableKucoin;
