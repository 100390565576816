class WebSocketManager {
  constructor() {
    this.connections = {};
    this.pingIntervals = {}; 
  }

  // Initialize a new WebSocket connection for an exchange
  initConnection(exchange, url, subscriptionData, token, pingInterval = 30000) {
    if (!this.connections[exchange]) {

      const wsUrl = `${url}?token=${token}`;
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        console.log(`${exchange} WebSocket connected`);
        this.subscribe(exchange, subscriptionData);  // Subscribe after connection opens

        // Start sending ping messages at regular intervals
        this.startPing(exchange, pingInterval);
      };

      ws.onmessage = (message) => this.handleMessage(exchange, message);

      ws.onclose = () => {
        console.log(`${exchange} WebSocket disconnected`);
        this.clearPing(exchange);  // Stop pinging when WebSocket closes
      };

      ws.onerror = (error) => console.error(`${exchange} WebSocket error:`, error);

      this.connections[exchange] = ws;
    }
  }

  // Send subscription message to the WebSocket
  subscribe(exchange, subscriptionData) {
    const ws = this.connections[exchange];
    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log(`${exchange} subscribing with:`, subscriptionData);
      ws.send(JSON.stringify(subscriptionData));
    } else {
      console.error(`${exchange} WebSocket is not open. Cannot subscribe.`);
    }
  }

  // Handle incoming WebSocket messages
  handleMessage(exchange, message) {
    const parsedMessage = JSON.parse(message.data);
    // console.log(`${exchange} message:`, parsedMessage);

    // If it's a pong response, do nothing (just log it)
    if (parsedMessage.type === 'pong') {
      console.log(`${exchange} received pong:`, parsedMessage);
      return;
    }

    // Handle other types of messages
    if (this.onMessageCallback) {
      this.onMessageCallback(parsedMessage);
    }
  }

  // Start sending ping messages at regular intervals
  startPing(exchange, pingInterval) {
    const ws = this.connections[exchange];
    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log(`${exchange} starting ping every ${pingInterval} ms`);

      // Set interval to send ping messages
      this.pingIntervals[exchange] = setInterval(() => {
        const pingMessage = JSON.stringify({ id: Date.now().toString(), type: 'ping' });
        console.log(`${exchange} sending ping:`, pingMessage);
        ws.send(pingMessage);
      }, pingInterval);
    }
  }

  // Stop sending ping messages (clear the interval)
  clearPing(exchange) {
    if (this.pingIntervals[exchange]) {
      clearInterval(this.pingIntervals[exchange]);
      delete this.pingIntervals[exchange];
    }
  }

  // Close WebSocket connection manually
  closeConnection(exchange) {
    const ws = this.connections[exchange];
    if (ws) {
      ws.close();
      delete this.connections[exchange];
      this.clearPing(exchange);  // Stop pinging when connection is closed
    }
  }

  // Set callback for messages to pass them to the UI
  onMessage(callback) {
    this.onMessageCallback = callback;
  }
}

export default new WebSocketManager();
