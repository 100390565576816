import axios from 'axios';

export const fetchKucoinBulletPublic = async () => {
  try {
    // const response = await axios.post(`http://localhost:5000/kucoin/get-auth-token?token=${process.env.REACT_APP_WONDER_AUTH_TOKEN}`);
    const response = await axios.get(`https://api.wondercryptobot.net/kucoin/get-auth-token?token=${process.env.REACT_APP_WONDER_AUTH_TOKEN}`);
    
    return { success: true, response }; 

  } catch (error) {
    console.error("Error fetching cryptocurrency data:", error.message);
    return { success: false, data: [] }; 
  }
};