import { fetchLatestCryptocurrencyListings } from "../CryptoPairSymbols";
import { binanceSymbols } from "../CryptoPairSymbols";
import WebSocketManager from "../WebSocketManagerBinance";

const binanceUrl = 'wss://stream.binance.com:9443/ws';
// const binanceUrl = "wss://testnet.binance.vision/ws"; //this is for testnet

//price data with 
// const binancePriceDataMsg =(symbolsArray) => ({
  const binancePriceDataMsg =(symbolsArray) => ({
  "method": "SUBSCRIBE",
  // "params": [
  //   "btcusdt@ticker_1h",
  //   "ethusdt@ticker_1h"
  // ],
  "params":symbolsArray,
  "id": 1
});


const binanceWebSocket = {
 async connect(onMessageCallback) {
    try {
      const { success, response } = await fetchLatestCryptocurrencyListings();
      if (success) {
        // const symbols = response?.data?.data
        // .slice(0, 10)
        // .map(crypto => crypto.symbol + "_USDT")
        // .filter(symbol => symbol !== "USDT_USDT")
        // .map(symbol => {
        //   const formattedSymbol = symbol?.replace("_", "")?.toLowerCase();
        //   return `${formattedSymbol}@ticker_1h`; 
        // });
        
        const formattedSymbol = binanceSymbols;
        // const symbols = formattedSymbol.map(symbol => `${symbol}@ticker_1h`);
        const symbols = formattedSymbol.map(symbol => `${symbol}@ticker`);
        
        // console.log(symbols,"SYMBOLS");
        const binancePriceData = binancePriceDataMsg(symbols); 
        WebSocketManager.onMessage(onMessageCallback);  // Set the message handler
        WebSocketManager.initConnection('binance', binanceUrl, binancePriceData); //which method to Subscription data put it here
      }
      else {
        console.error('Failed to fetch cryptocurrency symbols');
      }

    } catch (error) {
      console.error('Error connecting to binance WebSocket:', error.message);
    }
  },

  disconnect() {
    WebSocketManager.closeConnection('binance');
  },
};

export default binanceWebSocket;
