import pako from 'pako';

class WebSocketManager {
  static wsConnections = {};

  static initConnection(id, url, messages) {
    if (!this.wsConnections[id]) {
      this.wsConnections[id] = new WebSocket(url);

      this.wsConnections[id].onopen = () => {
        console.log(`WebSocket connected to ${url}`);
        // Send all subscription messages
        messages.forEach(message => {
          this.wsConnections[id].send(JSON.stringify(message));
        });
      };

      this.wsConnections[id].onmessage = async (event) => {
        let data;

        if (event.data instanceof Blob) {
          const arrayBuffer = await event.data.arrayBuffer();  // Convert Blob to ArrayBuffer
          
          // Decompress the GZIP data using pako
          const decompressed = pako.inflate(arrayBuffer, { to: 'string' });
          data = JSON.parse(decompressed);
        } else {
          // Handle uncompressed WebSocket messages
          data = JSON.parse(event.data);
        }

        // Check if it's a "ping" message and respond with "pong"
        if (data.ping) {
          console.log("Received ping, sending pong:", data.ping);
          this.wsConnections[id].send(JSON.stringify({ pong: data.ping }));
        } else {
          // Handle normal message data
          // console.log("Received WebSocket data:", data);
        }

        // Trigger any registered callbacks with the received data
        if (this.callbacks[id]) {
          this.callbacks[id].forEach(callback => callback(data));
        }
      };

      this.wsConnections[id].onclose = () => {
        console.log(`WebSocket connection to ${url} closed`);
        delete this.wsConnections[id];
      };

      this.wsConnections[id].onerror = (error) => {
        console.error(`WebSocket error on ${id}:`, error);
      };
    }
  }

  static callbacks = {};

  static onMessage(id, callback) {
    if (!this.callbacks[id]) {
      this.callbacks[id] = [];
    }
    this.callbacks[id].push(callback);  // Register callback for the given WebSocket connection ID
  }

  static closeConnection(id) {
    if (this.wsConnections[id]) {
      this.wsConnections[id].close();
      delete this.callbacks[id];  // Clear callbacks when closing the connection
    }
  }
}

export default WebSocketManager;
