export const cryptoListPairSymbols = [
    "BTC/USDT",
    "ETH/USDT",
    "BNB/USDT",
    "SOL/USDT",
    "USDC/USDT",
    "XRP/USDT",
    "DOGE/USDT",
    "TON/USDT",
    "ADA/USDT",
    "TRX/USDT",
];
export const binanceSymbols =[
    'btcusdt',
    'ethusdt',
    'bnbusdt',
    'solusdt',
    'usdcusdt',
    'xrpusdt',
    'dogeusdt',
    'tonusdt',
    'adausdy',
    'trxusdt'
];
export const bitfinexSymbols =[
    "BTCUSD",
    "ETHUSD",
    "SOLUSD",
    "XRPUSD",
    "TONUSD",
    "ADAUSD",
    "TRXUSD"
];
export const gateioSymbols =[
    'BTC_USDT',
    'ETH_USDT',
    'BNB_USDT',
    'SOL_USDT',
    'USDC_USDT',
    'XRP_USDT',
    'DOGE_USDT',
    'TON_USDT',
    'ADA_USDT',
    'TRX_USDT'
];
export const HtxSymbols = [
    'btcusdt',
    'ethusdt',
    'bnbusdt',
    'solusdt',
    'usdcusdt',
    'xrpusdt',
    'dogeusdt',
    'tonusdt',
    'adsusdt',
    'trxusdt'
];
export const kucoinSymbols = [
    'BTC-USDT',
    'ETH-USDT',
    'BNB-USDT',
    'SOL-USDT',
    'USDC-USDT',
    'XRP-USDT',
    'DOGE-USDT',
    'TON-USDT',
    'ADA-USDT',
    'TRX-USDT'
];
export const fetchLatestCryptocurrencyListings = async () => {
    try {
      
      const response =['BTC', 'ETH', 'BNB', 'SOL', 'USDC', 'XRP', 'DOGE', 'TON', 'ADA', 'TRX']
      return { success: true, response }; 
  
    } catch (error) {
      console.error("Error fetching cryptocurrency data:", error.message);
      return { success: false, data: [] }; 
    }
  };