// import CryptoDashboard from './components/cryptoTable';
import Dashboard from "./pages/Dashboard";

function App() {
  return (
    <div>
     {/* <CryptoDashboard/> */}
    <Dashboard/>
    </div>
  );
}

export default App;
