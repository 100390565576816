// src/components/SidebarItem.jsx
import React from "react";
import PropTypes from "prop-types";

const SidebarItem = ({ name, icon, isActive, onClick }) => {
  return (
    <li
      className={`flex items-center p-3 cursor-pointer transition-colors border-b ${
        isActive ? "text-[#1FF6D0] border-[#5A5A5A]" : "border-transparent hover:bg-transparent"
      }`}
      onClick={onClick}
    >
      <span className="mr-2">{icon}</span>
      <span>{name}</span>
    </li>
  );
};

SidebarItem.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired, // Icon is a React element, not a string
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SidebarItem;
