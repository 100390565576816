import { fetchLatestCryptocurrencyListings } from "../CryptoPairSymbols";
import { HtxSymbols } from "../CryptoPairSymbols";
import WebSocketManager from "../WebSocketManagerHtx"; // Assuming WebSocketManager is reusable

const htxUrl = 'wss://api.huobi.pro/ws'; // HTX WebSocket URL

// Generate subscription message for HTX price data
const htxPriceDataMsg = (symbolsArray) => {
    return symbolsArray.map(symbol => ({
        "sub": `market.${symbol}.ticker`, // Subscription channel for price data
        "id": `${symbol}_ticker_subscription` // Unique ID for each subscription
    }));
};

const HtxWebSocket = {
    async connect(onMessageCallback) {
      try {
        // Fetch the latest cryptocurrency listings
        const { success, response } = await fetchLatestCryptocurrencyListings();
  
        if (success) {
        // const symbols = response?.data?.data
        // .slice(0, 10)
        // .map(crypto => crypto.symbol + "_USDT")
        // .filter(symbol => symbol !== "USDT_USDT")
        // .map(symbol => {
        //   const formattedSymbol = symbol?.replace("_", "")?.toLowerCase();
        //   return `${formattedSymbol}@ticker_1h`; 
        // });
        // BNBUSD USDCUSD DGEUSD this symbole are not in this list(https://api-pub.bitfinex.com/v2/conf/pub:list:pair:exchange)
          const symbols = HtxSymbols;
          // const symbols = formattedSymbol.map(symbol => `${symbol}@ticker_1h`);
          const htxPriceData = htxPriceDataMsg(symbols);
  
          // Initialize WebSocket connection and register callback
          WebSocketManager.initConnection('htx', htxUrl, htxPriceData);
          WebSocketManager.onMessage('htx', onMessageCallback);  // Register your callback
        } else {
          console.error('Failed to fetch cryptocurrency symbols');
        }
      } catch (error) {
        console.error('Error connecting to HTX WebSocket:', error.message);
      }
    },
  
    disconnect() {
      WebSocketManager.closeConnection('htx');
      console.log("Disconnected from HTX WebSocket");
    },
  };
  
export default HtxWebSocket;
