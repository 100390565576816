import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

class WebSocketTrading {
  constructor() {
    this.connections = {};
  }

  // Initialize a new WebSocket connection for Binance
  initConnection(dynamicData) {
    const exchange = 'Binance';
    const url = 'wss://ws-api.binance.com:443/ws-api/v3';
    // const url = 'wss://testnet.binance.vision/ws-api/v3';
    // const apiKey = process.env.REACT_APP_BINANCE_API_KEY;


    if (!this.connections[exchange]) {
      const ws = new WebSocket(url);

      ws.onopen = async () => {
        console.log(`${exchange} WebSocket connected`);

        const uniqueId = uuidv4();
        const timestamp = Date.now();

        // Prepare the request payload as a query string (without signature)
        const params = {
          symbol: dynamicData.selectedSymbol,//BTCUSDT
          // symbol: "TRXUSDT",
          side: dynamicData.side,
          type: "MARKET",
          quoteOrderQty: dynamicData.quantity,
          newOrderRespType: "FULL",
          timestamp: timestamp,
        };

        
        const signature = await this.handleSignReq(params);
        console.log(signature,"this is it");
        

        const subscriptionData = await {
          id: uniqueId,
          method: "order.place",
          params: {
            ...params,
            apiKey: process.env.REACT_APP_BINANCE_API_KEY,
            signature: signature.data,
          }
        };



        // this.subscribe(exchange, subscriptionData);
      };

      ws.onmessage = (message) => this.handleMessage(exchange, message);

      ws.onclose = () => console.log(`${exchange} WebSocket disconnected`);

      ws.onerror = (error) => console.error(`${exchange} WebSocket error:`, error);

      this.connections[exchange] = ws;

      // Set up ping/pong handling
      this.setupPingPong(ws);
    }
  }


  async handleSignReq(params){
    try {

      const response =  await fetch(`http://localhost:5000/binance/query_string?token=${process.env.REACT_APP_WONDER_AUTH_TOKEN}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      
      const data = await response.text();
      
      return {data};
    } catch (error) {
      console.error();
    }
  }

  // Send subscription message to the WebSocket
  subscribe(exchange, subscriptionData) {
    const ws = this.connections[exchange];
    if (ws && ws.readyState === WebSocket.OPEN) {
      // console.log(`${exchange} subscribing with:`, subscriptionData);
      ws.send(JSON.stringify(subscriptionData));
    } else {
      console.error(`${exchange} WebSocket is not open. Cannot subscribe.`);
    }
  }

  // Handle incoming WebSocket messages
 // Handle incoming WebSocket messages
handleMessage(exchange, message) {
  const parsedMessage = JSON.parse(message.data);

  // Handle ping/pong frames
  if (parsedMessage.method === 'pong') {
    console.log('Received pong:', parsedMessage);
  } else {
    // Pass data to the UI if callback is set and parsedMessage is structured as expected
    if (this.onMessageCallback) {
      try {
        if (parsedMessage && parsedMessage.status !== undefined) {
          this.onMessageCallback(parsedMessage);
        } else {
          console.warn('Parsed message does not have a status property:', parsedMessage);
        }
      } catch (error) {
        console.error('Error in onMessageCallback:', error);
      }
    } else {
      console.warn('No onMessageCallback defined.');
    }
  }
}

  // handleMessage(exchange, message) {
  //   const parsedMessage = JSON.parse(message.data);
  //   // console.log(`${exchange} message:`, parsedMessage);

  //   // Handle ping/pong frames
  //   if (parsedMessage.method === 'pong') {
  //     console.log('Received pong:', parsedMessage);
  //   } else {
  //     // Pass data to the UI
  //     if (this.onMessageCallback) {
  //       this.onMessageCallback(parsedMessage);
  //     }
  //   }
  // }

  // Setup ping/pong handling
  setupPingPong(ws) {
    const sendPong = (pingPayload) => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ method: 'pong', payload: pingPayload }));
        console.log('Sent pong:', pingPayload);
      }
    };

    const pingHandler = setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        const pingPayload = {}; // Add your desired payload if needed
        ws.send(JSON.stringify({ method: 'ping', payload: pingPayload }));
        console.log('Sent ping:', pingPayload);
      }
    }, 180000); // Send ping every 3 minutes

    ws.addEventListener('message', (message) => {
      const parsedMessage = JSON.parse(message.data);
      if (parsedMessage.method === 'ping') {
        sendPong(parsedMessage.payload);
      }
    });

    // Clear interval when WebSocket is closed
    ws.onclose = () => {
      clearInterval(pingHandler);
      console.log('Ping/Pong interval cleared.');
    };
  }

  // Method to close WebSocket connection manually
  closeConnection(exchange) {
    const ws = this.connections[exchange];
    if (ws) {
      ws.close();
      delete this.connections[exchange];
    }
  }

  // Set callback for messages to pass them to the UI
  onMessage(callback) {
    this.onMessageCallback = callback;
  }
}

// Instantiate and export the WebSocketTrading
export default WebSocketTrading;
