class WebSocketManager {
  constructor() {
    this.connections = {};
  }

  // Initialize a new WebSocket connection for an exchange
  initConnection(exchange, url, subscriptionData) {
    if (!this.connections[exchange]) {
      const ws = new WebSocket(url);

      ws.onopen = () => {
        console.log(`${exchange} WebSocket connected`);
        this.subscribe(exchange, subscriptionData);  // Subscribe after connection opens
      };

      ws.onmessage = (message) => this.handleMessage(exchange, message);

      ws.onclose = () => console.log(`${exchange} WebSocket disconnected`);

      ws.onerror = (error) => console.error(`${exchange} WebSocket error:`, error);

      this.connections[exchange] = ws;
    }
  }

  // Send subscription message to the WebSocket
  subscribe(exchange, subscriptionData) {
    const ws = this.connections[exchange];
    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log(`${exchange} subscribing with:`, subscriptionData);
      ws.send(JSON.stringify(subscriptionData));
    } else {
      console.error(`${exchange} WebSocket is not open. Cannot subscribe.`);
    }
  }

  // Handle incoming WebSocket messages
  handleMessage(exchange, message) {
    const parsedMessage = JSON.parse(message.data);
    // console.log(`${exchange} message:`, parsedMessage);

    // Handle other types of messages
    // Pass data to the UI
    if (this.onMessageCallback) {
      this.onMessageCallback(parsedMessage);
    }
  }

  // Method to close WebSocket connection manually
  closeConnection(exchange) {
    const ws = this.connections[exchange];
    if (ws) {
      ws.close();
      delete this.connections[exchange];
    }
  }

  // Set callback for messages to pass them to the UI
  onMessage(callback) {
    this.onMessageCallback = callback;
  }
}

export default new WebSocketManager();
